import ScrollMagic from 'scrollmagic';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';

export function scrolEvents(controller , scene){
	require('fslightbox');
    if(document.getElementById('site-header')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            .setClassToggle('#site-header','active').addTo(controller)
        )
    };
	
    if(document.getElementById('pagetop')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            .setClassToggle('#pagetop','active').addTo(controller)
        )
    }
	
	const scroll_anime_name = 'scroll-anime';
	const scroll_anime_elem = document.getElementsByClassName(scroll_anime_name);
	if(scroll_anime_elem.length > 0){
		for(let i = 0; i < scroll_anime_elem.length; i++){
			let scroll_node = scroll_anime_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(scroll_node,'_active').addTo(controller)
			)
		}
	}
    
	const scroll_custum_name = 'scroll-custum';
	const scroll_custum_elem = document.getElementsByClassName(scroll_custum_name);
	if(scroll_custum_elem.length > 0){
		for(let i = 0; i < scroll_custum_elem.length; i++){
			let custum_node = scroll_custum_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: custum_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(custum_node,'_active').addTo(controller)
			)
		}
	}
	
	const scroll_img_name = 'scroll-img';
	const scroll_img_elem = document.getElementsByClassName(scroll_img_name);
	if(scroll_img_elem.length > 0){
		for(let i = 0; i < scroll_img_elem.length; i++){
			let scroll_img_node = scroll_img_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_img_node,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(scroll_img_node,'__active').addTo(controller)
			)
		}
	}
    
    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
		header: '[data-scroll-header]',
		speed: 600,
		easing: 'easeInOutQuart',
		speedAsDuration: false,
		durationMin: 600
	});
	
	const plane = document.getElementById('headerplane');
	if(plane){
		document.getElementById('site-header').classList.add('plane');
	}
	
	
	const secondelem = document.getElementById('secondelem');
	if(secondelem){
		const slice_text = document.getElementsByClassName('txt_slice');
		for(let i = 0; i < slice_text.length; i++){
			slicer(slice_text[i]);
		}
		function slicer(elem){
			const txtchange =  elem.innerHTML.replace(/<br>/g, '_');
			const txt =  txtchange.replace(/&amp;/g, '&');
			elem.innerHTML = '';
			txt.split('').forEach(function(c) {
				if(c == '_'){
					elem.innerHTML += '<br>';
				}
				else if(c == ' '){
					elem.innerHTML += '<span>&nbsp;</span>';
				}
				else{
					elem.innerHTML += '<span>'+c+'</span>';
				}
			});
		}
		setTimeout(function(){
			secondelem.classList.add('active');
		},400);
	}
	
	const video = document.getElementById('video');
	if(video){
		setTimeout(function(){
			video.play();
		},200);
	}
	
	const fullSize = document.getElementsByClassName('fullSize');
	if(fullSize.length > 0){
		for(let i = 0; i < fullSize.length; i++){
			fullSize[i].style.maxHeight = window.innerHeight + 'px';
		}
	}

}