import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';

export function index(){
	const brandSwipe = document.getElementById('brandSwipe');
	if(brandSwipe){
		new Swiper(brandSwipe, {
			spaceBetween: 0,
			centeredSlides: true,
			speed: 6000,
			autoplay: {
				delay: 1,
			},
			loop: true,
			slidesPerView:'auto',
			allowTouchMove: false,
			disableOnInteraction: true
		});
	}
};